import { React, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { useEffect } from "react";

// layouts and pages
import RootLayout from "./components/RootLayout";
import Chat from "./pages/Chat";
import Test from "./pages/Test";
import Demo from "./pages/Demo";
import Login from "./pages/Login";
import { auth } from "./components/auth/firebase";
import Checkout from "./pages/Checkout";
import CheckoutForm from "./pages/CheckoutForm";
import { useAuthState } from 'react-firebase-hooks/auth';

const PrivateRoute = ({ children }) => {
  const [user, loading, error] = useAuthState(auth);
  
  if (loading) {
    return null; // or a loading spinner
  }

  return user ? children : <Navigate to="/login" />;
}

function App() {
  return (
    <Router>
      <Routes>
        <Route index element={<PrivateRoute><Demo /></PrivateRoute>}></Route>
        <Route path="/login" element={<Login />} />
        <Route path="/checkout" element={ <PrivateRoute><CheckoutForm /></PrivateRoute>} />
        <Route path="/demo/:model_id" element={<Demo />} /> 
        <Route path="/chat" element={<PrivateRoute><Demo /></PrivateRoute>} />
        <Route path="/test/:model_id" element={<PrivateRoute><Test /></PrivateRoute>} />
      </Routes>
    </Router>
  );
}

export default App;


