import React, { useState, useEffect } from "react";
import { chakra, Flex, Icon, Text, Box, Stack, Button, SimpleGrid, Image, HStack, Link as ChakraLink } from "@chakra-ui/react";
//import { useAuth } from "../../hooks/useAuth";
import { simpleFetch } from "../utils/fetchUtils"
import { auth } from "../components/auth/firebase";

const CheckoutForm = () => {
  const [frequency, setFrequency] = useState("month");
  const [hrefStarter, setHrefStarter] = useState('');
  const [hrefPro, setHrefPro] = useState('');
  //const { user } = useAuth()
  const base_create_checkout_route = process.env.REACT_APP_CREATE_CHECKOUT_ROUTE || 'http://localhost:8080/stripe/create-checkout-session'
  const [plan, setPlan] = useState('')
  const [user, setLoggedUser] = useState(null);

  const [hrefTrial, setHrefTrial] = useState('');

  //Login + Logout listener
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((newUser) => {
      setLoggedUser(newUser);
    });
    return unsubscribe;
  }, []);


  useEffect(() => {
    const link = frequency === 'month' ? '/product_1' : '/product_2';
    setHrefStarter(base_create_checkout_route+link+'/'+user?.email);
    const linkPro = frequency === 'month' ? "/product_3" : "/product_4";
    setHrefPro(base_create_checkout_route+linkPro+'/'+user?.email);
    setHrefTrial(base_create_checkout_route+'/product_5/'+user?.email);
  }, [frequency, user]);

  useEffect(() => {
    if(user !== null) {
    getPlanExpDate(user?.email).then((data) => {
      if(data) {
        setPlan(data.plan)
      }
    })}
  }, [user])

  async function getPlanExpDate(user_id) {
    const fetch_url = process.env.REACT_APP_GET_PLAN_DETAILS_ROUTE || 'http://localhost:8080/stripe/get_plan_details'
    const res = await simpleFetch(fetch_url, 'POST', {user_id: user_id})
    return res
  }

  const Feature = (props) => {
    return (
      <Flex align="center">
      <Flex shrink={0}>
        <Icon
          boxSize={5}
          mt={1}
          mr={2}
          color="brand.500"
          _dark={{
            color: "brand.300",
          }}
          viewBox="0 0 20 20"
          fill="currentColor"
        >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              ></path>
            </Icon>
          </Flex>
          <Box ml={4}>
            <chakra.span
              mt={2}
              color="gray.700"
              _dark={{
                color: "gray.400",
              }}
            >
              {props.children}
            </chakra.span>
          </Box>
        </Flex>
      );
    };

return (
  <Flex
  w="full"
  bg="#f3f3f3"
  _dark={{
    bg: "#f3f3f3",
  }}
  p={25}
  alignItems="center"
  justifyContent="center"
>
  <Box
    py="16px"
    px="10"
    bg="f3f3f3"
    _dark={{
      bg: "gray.700",
    }}
  >
         <Box w="full" px={[10,10 , 4]} mx="auto" textAlign="center">
            <HStack justifyContent={"center"}>
            <Image src="Gitomer_Logo.png" w="70px" h="90px" paddingBottom={"4px"} paddingX = "1px"/>
            <Text mb={2} fontSize="4xl" fontWeight="bold" lineHeight="tight">
              Plans & Pricing
            </Text>
        </HStack>
        <chakra.p
          mb={2}
          fontSize={["lg", "lg", "xl"]}
          color="gray.600"
          _dark={{
            color: "gray.400",
          }}
        >
        </chakra.p>        
        <Flex justify="center" mx={["auto", 0]} mb={-2}>
              <Stack
                direction="row"
                justify="space-between"
                p="2"
                textAlign="center"
                rounded="md"
                bg="white"
                _dark={{
                  bg: "white",
                }}
                //border={"1px"}
                //borderColor={"red.600"}
                color={"red.600"}
                fontWeight={"bold"}
              >
            <Box
              colorScheme="red"
              //variant={frequency === "month" ? "solid" : "ghost"}
              //onClick={() => setFrequency("month")}
              px={6}
            >
              30-Day Trial
            </Box>
          </Stack>
        </Flex>
      </Box>
      <Box maxW="7xl" py="10" mx="auto">
        <SimpleGrid columns={[1,1 , 1, 1]} gap={[16, 8]}>
          <Box
            rounded={["none", "lg"]}
            shadow={["none", "md"]}
            bg="white"
            _dark={{
              bg: "gray.800",
            }}
          >
            <Flex
              direction="column"
              justify="space-between"
              p="6"
              borderBottomWidth="1px"
              color="gray.200"
              _dark={{
                color: "gray.600",
              }}
            >
              <chakra.p
                mb={1}
                fontSize="lg"
                fontWeight="semibold"
                color="gray.700"
                _dark={{
                  color: "gray.400",
                }}
              >
                Beta
              </chakra.p>
              <Text
                mb={2}
                fontSize="5xl"
                fontWeight={["bold", "extrabold"]}
                color="gray.900"
                _dark={{
                  color: "gray.50",
                }}
                lineHeight="tight"
              >
                ${frequency === "month" ? 9.99 : 9.99}
                <chakra.span
                  fontSize="2xl"
                  fontWeight="medium"
                  color="gray.600"
                  _dark={{
                    color: "gray.400",
                  }}
                >
                  {" "}
                  for 30 days
                </chakra.span>
              </Text>

              <ChakraLink
                    w={["full", "full", "auto"]}
                    display="inline-flex"
                    alignItems="center"
                    justifyContent="center"
                    px={5}
                    py={2}
                    border="solid transparent"
                    borderColor={"red.600"}
                    fontWeight="bold"
                    rounded="md"
                    shadow="md"
                    bg = "white"
                    color={"red.600"}
                    href={hrefStarter}
              >
                {(plan === 'Starter Subscription') ? (<>Currently Active</>) : (<>Start Now</>)}
              </ChakraLink>

            </Flex>
            <Stack direction="column" p="6" spacing="3" flexGrow="1">
              {frequency === "year" ? <Feature> 12 Month Subscription </Feature>: null}
              <Feature>Early access to AI Jeffrey Gitomer</Feature>
              <Feature>First 250 users only</Feature>
              <Feature>Sales-tips on demand</Feature>
              <Text fontSize={"xs"} textAlign = "center" fontStyle={"italic"} color={"gray.600"} w = "400px">The charge for the place with all the sales advice you will ever need is $9.99 for the 30-day Beta trial version. When the trial period is close to ending you will receive a prompt asking if you wish to continue and which level of service you prefer. You will also have the option to opt out. However, we are confident you will want to continue to get the advice that helps you builds sales, grows your business, and makes you more money!</Text>
            </Stack>
          </Box>
        </SimpleGrid>
        <HStack align={"center"} marginTop={"2"} justifyContent={"center"}><Text fontSize={"12px"} fontWeight={"bold"} textAlign={"center"}>Powered by</Text><Image src = "Hyperreal Logo.png" h={"8px"}/></HStack>
      </Box>
    </Box>
  </Flex>
);
};

export default CheckoutForm;