export const simpleFetch = async (url, method, body) => {
    let res;
    try {
      res = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + process.env.REACT_APP_JWT_TOKEN,
        },
        body: JSON.stringify(body),
      });
      if (!res.ok) {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
    if (res.status !== 200) {
      console.log(res.status);
      return false;
    }
    const data = await res.json();
    return data;
  };
