import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

const AudioStream = ({
  voiceId,
  text,
  apiKey,
  onAudioEnd,
  onAudioStart,
  streamingFlag,
  setAudioStreamFlag
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [previousText, setPreviousText] = useState(text);
  const canvasRef = useRef(null);
  const audioContextRef = useRef(new (window.AudioContext || window.webkitAudioContext)());
  const analyserRef = useRef(audioContextRef.current.createAnalyser());
  const [audioContextInitialized, setAudioContextInitialized] = useState(false);
  const [canvasHeight, setCanvasHeight] = useState(0);

    const initializeAudioContext = () => {
      if (!audioContextInitialized) {
        audioContextRef.current.resume().then(() => {
          setAudioContextInitialized(true);
        });
      }
    };

  useEffect(() => {
    if (text && !streamingFlag) {
      setAudioStreamFlag(true);
      startStreaming();
      setPreviousText(text);
    }
  }, []);

  const opts = {
    smoothing: 0.6,
    fft: 8,
    minDecibels: -70,
    scale: 0.2,
    glow: 10,
    color1: [203, 36, 128],
    color2: [41, 200, 192],
    color3: [24, 137, 218],
    fillOpacity: 0.6,
    lineWidth: 1,
    blend: "screen",
    shift: 50,
    width: 50,
    amp: 2
  };

  let freqs = new Uint8Array(analyserRef.current.frequencyBinCount);

  const shuffle = [1, 3, 0, 4, 2];

  const range = (i) => Array.from(Array(i).keys());

  const freq = (channel, i) => {
    const band = 2 * channel + shuffle[i] * 6;
    return freqs[band];
  };

  const scale = (i) => {
    const x = Math.abs(2 - i);
    const s = 3 - x;
    return s / 3 * opts.amp;
  };

  const path = (ctx, channel, WIDTH, HEIGHT) => {
      
  // Read color1, color2, color2 from the opts
  const color = opts[`color${channel + 1}`].map(Math.floor);
  
  // turn the [r,g,b] array into a rgba() css color
  ctx.fillStyle = `rgba(${color}, ${opts.fillOpacity})`;
  
  // set stroke and shadow the same solid rgb() color
  ctx.strokeStyle = ctx.shadowColor = `rgb(${color})`;
  
  ctx.lineWidth = opts.lineWidth;
  ctx.shadowBlur = opts.glow;
  ctx.globalCompositeOperation = opts.blend;
  
  const m = HEIGHT / 2; // the vertical middle of the canvas

  // for the curve with 5 peaks we need 15 control points

  // calculate how much space is left around it
  const offset = (WIDTH - 15 * opts.width) / 2;

  // calculate the 15 x-offsets
  const x = range(15).map(
    i => offset + channel * opts.shift + i * opts.width
  );
  
  // pick some frequencies to calculate the y values
  // scale based on position so that the center is always bigger
  const y = range(5).map(i =>
    Math.max(0, m - scale(i) * freq(channel, i))
  );
    
  const h = 2 * m;

  ctx.beginPath();
  ctx.moveTo(0, m); // start in the middle of the left side
  ctx.lineTo(x[0], m + 1); // straight line to the start of the first peak
  
  ctx.bezierCurveTo(x[1], m + 1, x[2], y[0], x[3], y[0]); // curve to 1st value
  ctx.bezierCurveTo(x[4], y[0], x[4], y[1], x[5], y[1]); // 2nd value
  ctx.bezierCurveTo(x[6], y[1], x[6], y[2], x[7], y[2]); // 3rd value
  ctx.bezierCurveTo(x[8], y[2], x[8], y[3], x[9], y[3]); // 4th value
  ctx.bezierCurveTo(x[10], y[3], x[10], y[4], x[11], y[4]); // 5th value
  
  ctx.bezierCurveTo(x[12], y[4], x[12], m, x[13], m); // curve back down to the middle
  
  ctx.lineTo(1000, m + 1); // straight line to the right edge
  ctx.lineTo(x[13], m - 1); // and back to the end of the last peak
  
  // now the same in reverse for the lower half of out shape
  
  ctx.bezierCurveTo(x[12], m, x[12], h - y[4], x[11], h - y[4]);
  ctx.bezierCurveTo(x[10], h - y[4], x[10], h - y[3], x[9], h - y[3]);
  ctx.bezierCurveTo(x[8], h - y[3], x[8], h - y[2], x[7], h - y[2]);
  ctx.bezierCurveTo(x[6], h - y[2], x[6], h - y[1], x[5], h - y[1]);
  ctx.bezierCurveTo(x[4], h - y[1], x[4], h - y[0], x[3], h - y[0]);
  ctx.bezierCurveTo(x[2], h - y[0], x[1], m, x[0], m);
  
  ctx.lineTo(0, m); // close the path by going back to the start
  
  ctx.fill();
  ctx.stroke();
  };

  const drawWaveform = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;

    analyserRef.current.smoothingTimeConstant = opts.smoothing;
    analyserRef.current.fftSize = Math.pow(2, opts.fft);
    analyserRef.current.minDecibels = opts.minDecibels;
    analyserRef.current.maxDecibels = 0;
    analyserRef.current.getByteFrequencyData(freqs);

    canvas.width = WIDTH;
    canvas.height = HEIGHT;

    path(ctx, 0, WIDTH, HEIGHT);
    path(ctx, 1, WIDTH, HEIGHT);
    path(ctx, 2, WIDTH, HEIGHT);

    requestAnimationFrame(drawWaveform);
  };

  const startStreaming = async () => {
    setLoading(true);
    setError("");

    const baseUrl = "https://api.elevenlabs.io/v1/text-to-speech";
    const headers = {
      "Content-Type": "application/json",
      "xi-api-key": apiKey,
    };

    const requestBody = {
      text,
    };

    try {
      const response = await axios.post(`${baseUrl}/${voiceId}`, requestBody, {
        headers,
        responseType: "blob",
      });

      if (response.status === 200) {
        const audio = new Audio(URL.createObjectURL(response.data));
        const source = audioContextRef.current.createMediaElementSource(audio);
        
        // Connect the source to the analyser
        source.connect(analyserRef.current);
      
        // Also connect the source directly to the destination so it can be heard
        source.connect(audioContextRef.current.destination);
      
        audio.addEventListener("ended", onAudioEnd);
        audio.play();
        setCanvasHeight(180);
        onAudioStart();
        drawWaveform();
      } else {
        setError("Error: Unable to stream audio.");
      }
    } catch (error) {
      setError("Error: Unable to stream audio.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{"marginBottom": "120px", "marginTop": "-60px"}}>
      {loading && <div></div>}
      <button onClick={initializeAudioContext}></button>
      {error && <div>{error}</div>}
      <canvas ref={canvasRef} width={800} height={canvasHeight}></canvas>
    </div>
  );
};

export default AudioStream;
