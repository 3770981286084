import React from 'react'
import { Image, Text, Box, Flex, HStack } from '@chakra-ui/react'
import { useState, useEffect, useMemo, useRef } from 'react';
import ReactSiriwave from 'react-siriwave';

const CustomImageComponent = ({ defaultImage, imageSource, audioUrl, loadingMessage, onClickImageSource }) => {
    const imageDirectory = "/slideshow_pics/"
    const imagesArray = ['image1.jpeg', 'image2.jpeg', 'image4.jpeg', 'image5.jpeg', 'image6.jpeg', 'image7.jpeg', 'image8.jpeg'];
    const quotesArray = [
      "People don't like to be sold, but love to buy.",
      "Take action every day - some small dose at a time.",
      "Sell yourself before you try to sell your company or your product.",
      "Resilience is not what happens to you. It’s how you react to, respond to, and recover from what happens to you.",
      "You don't earn loyalty in a day. You earn loyalty day-by-day.",
      "Rules are in every company for everyone to follow. Eh, except salespeople.",
      "A person who seems to have all of the answers, usually isn't listening.",
      "You don't get great at selling in a day. You get great at selling day by day.",
      "Don't dwell on the problem; concentrate on the solution.",
      "Invest time, don't spend it.",
      "Differentiate with value or die with price.",
      "If you make a sale, you can earn a commission. If you make a friend, you can earn a fortune!",
      "Most people will not do the hard work it takes to make success easy. Don't be like most people.",
      "Before you can get what you want, you have to know what you want,and make a game plan to get it",
      "Most salespeople are half prepared. They know everything about their company and their product. They know nothing about their prospect."
    ]; // replace with your actual quotes
  
    const images = imagesArray.map(img => imageDirectory + img);

    const [activeIndex, setActiveIndex] = useState(0);
    const [containerWidth, setContainerWidth] = useState(0);
    const size = useMemo(() => images.length, []);
    const timer = useRef(null);
    const containerRef = useRef(null);

    const map = useMemo(() => {
        const map = new Map();
        const len = images.length;
        let i = len;

        while (i > 0) {
            map.set((activeIndex + len - i) % len, --i);
        }
        return map;
    }, [activeIndex]);

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;
        const handler = () => {
            const { width } = container.getBoundingClientRect();
            setContainerWidth(width);
        };
        handler();
        window.addEventListener("resize", handler);
        return () => window.removeEventListener("resize", handler);
    }, []);

    useEffect(() => {
        if (loadingMessage) {
            timer.current = setInterval(() => setActiveIndex((cur) => (cur + 1) % size), 5000);
            return () => clearInterval(timer.current);
        }
    }, [loadingMessage, size]);
  
    if (loadingMessage) {
        return (
            <Flex direction="column" justifyContent="center" alignItems="center" my="auto">
                <HStack w = "100%" display = "flex" justifyContent={"center"} marginBottom={"3%"}>
                <Box
                    position="relative"
                    width={{ base: 350, md: 350, xl: 350 }}
                    height={{ base: 230, md: 230, xl: 230 }}
                    ref={containerRef}
                    marginRight={"3%"}
                >
                    {images.map((image, i) => {
                        const factor = size - 1 - map.get(i);
                        return (
                            <Box
                                key={image}
                                top={0}
                                right={`${0 - factor * 18}px`}
                                borderRadius="lg"
                                position="absolute"
                                backgroundImage={`url(${image})`}
                                backgroundSize="cover"
                                backgroundRepeat="no-repeat"
                                width="inherit"
                                height="inherit"
                                transform={`scale(${1 - 0.09 * factor})`}
                                zIndex={map.get(i)}
                                boxShadow="15px 0 10px -3px rgba(0,0,0,0.15)"
                                transition="z-index 0.6s ease, transform 0.6s ease, right 0.3s ease, top 0.3s ease"
                            />
                        );
                    })}
                </Box>
                <Text fontSize="2xl" fontWeight="bold" color="white" textAlign={"center"} paddingX="2" marginTop={"2"} maxW = "35%">
                    "{quotesArray[activeIndex]}"
                </Text>
                </HStack>
                <Text fontSize="xl" color="white" fontStyle={"italic"} fontWeight={"semibold"} textAlign={"center"} paddingX="2" marginTop={"3"}>
                    AI Jeffrey is thinking...
                </Text>
            </Flex>
        );
    }
    else {
      return (
        <>
        <Flex justifyContent={"flex-end"} alignItems={"center"} h = "100%" w = "100%" marginRight={"12%"} marginBottom={"4%"}>
        <Text fontSize="40px" fontWeight="bold" color="white" textAlign={"right"} paddingX="2" lineHeight={"shorter"}>
          ASK
          <br></br> A<span style={{ fontFamily: 'courier', fontSize: "44px" }}>i</span> JEFFREY GITOMER
          <br></br> ANY QUESTION
        </Text>
        </Flex>
        {audioUrl !== "" ? <Box display = "flex" justifyContent="center" alignItems = "center" w="60%" h = "60px"><ReactSiriwave theme='ios9' cover={true} /></Box> : null}
        </>
      );
    } {/*else {
      return (
        <>
        <a href={onClickImageSource} target="_blank" rel="noopener noreferrer">
        <Image maxH = "200px" src={imageSource}/>
        </a>
        <a href={"https://www.buygitomer.com/"} target='_blank' rel='noopener noreferrer'>
          <Text fontSize="xl" fontWeight="bold" color="blue" textAlign={"center"} paddingX="2">
            Buy It Now!
          </Text>
        </a>
        </>
      );
    }*/}
  }

export default CustomImageComponent