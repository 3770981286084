import React, { useEffect, useState } from 'react';
import { Fade } from '@chakra-ui/react';
import "firebaseui/dist/firebaseui.css";
import * as firebaseui from 'firebaseui';
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import { Box, Image, Text, Button, VStack, Flex, HStack } from '@chakra-ui/react';

const Login = () => {

    // State to hold the current quote index and the list of quotes
    const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);
    const quotes = [
        "People don't like to be sold, but love to buy.",
        "Take action every day - some small dose at a time.",
        "Sell yourself before you try to sell your company or your product.",
        "Resilience is not what happens to you. It’s how you react to, respond to, and recover from what happens to you.",
        "You don't earn loyalty in a day. You earn loyalty day-by-day.",
        "Rules are in every company for everyone to follow. Eh, except salespeople.",
        // ... add as many quotes as you want
    ];

    useEffect(() => {
        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
        
        ui.start('#firebaseui-auth-container', {
            signInOptions: [
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                firebase.auth.EmailAuthProvider.PROVIDER_ID
            ],
            signInFlow: 'popup',
            callbacks: {
                signInSuccessWithAuthResult: function(authResult, redirectUrl='/chat') {
                    window.location.href = redirectUrl;
                    return true;
                }
            }
        })
        // Set an interval to update the quote every 20 seconds
        const quoteInterval = setInterval(() => {
            setCurrentQuoteIndex(prevIndex => (prevIndex + 1) % quotes.length);
        }, 15000);
        // Cleanup the interval when the component is unmounted
        return () => {
            clearInterval(quoteInterval);
        };
    }, []);
    return (
        <>
        <Box 
        bg = "#f3f5f4" 
        h = "100vh" 
        display={"flex"} 
        bgImage={"login_bg.png"}
        bgSize = "contain" 
        bgRepeat={"no-repeat"} 
        bgAttachment="fixed" 
        bgPosition={"center"} 
        alignItems={"center"} 
        justifyContent={"center"}>
        <Flex direction = "column" h = "100vh" w = "100%" alignItems={"center"} justifyContent={"center"}>
        <Box w = "75%" bg = "white" display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}  p ="16" boxShadow={"xl"} borderRadius={"16"}>
            <HStack>
                <Box h = "100%" borderRightWidth={"2px"} borderColor={"gray.200"} paddingRight={"4"}>
                    <VStack>
                    <Text fontSize = "lg" fontWeight={"bold"} color = "red.700">Jeffrey's Words of Wisdom</Text>
                    <Fade in={true} key={currentQuoteIndex} style={{ transitionDuration: "1s" }}>
                    <Text marginBottom={"4"} fontWeight={"bold"} textAlign={"center"}>"{quotes[currentQuoteIndex]}"</Text>
                    </Fade>
                    <a class="twitter-timeline" data-width="350" data-height="250" href="https://twitter.com/gitomer?ref_src=twsrc%5Etfw">Tweets by gitomer</a>
                    </VStack>
                </Box>
                <Box>
                <Box display={"flex"}  justifyContent={"center"}>
            <VStack>
            <Text fontSize = "lg" fontWeight={"bold"} color = "red.700">King of Sales</Text>
            <Text fontSize = "xl" fontWeight={"bold"} color = "red.700">On-Demand Jeffrey Gitomer</Text>
            <Image maxH = "200px" src='gitomer_login-removebg-preview.png'/>
            <Text fontSize = "lg" textAlign={"center"} fontWeight={"bold"} color = "red.700">Learn how to make more sales and more MONEY!</Text>
            </VStack>
          </Box>
          <Box w = "100%" marginTop={"4"}>
          <div id="firebaseui-auth-container"></div>
          </Box>
          <VStack marginTop={"2"}>
            <Text fontSize = "xs" color = "gray.700" fontStyle={"italic"} textAlign={"center"}>By creating an account you are accepting the <span style={{color:"blue"}}><a href='https://docs.google.com/document/d/1QlnqteubAzC6lSr5Pdm62D459U0kODPD/edit?usp=sharing&ouid=102363935403938524336&rtpof=true&sd=true' target='_blank'>Beta Tester Agreement</a></span>, our <span style={{color:"blue"}}><a href='https://docs.google.com/document/d/1VO4USmJDKWM75WoO2GtZkb8Gcmkh-kPf/edit?usp=sharing&ouid=102363935403938524336&rtpof=true&sd=true' target='_blank'>Terms of Service</a></span> and <span style={{color:"blue"}}><a href='https://docs.google.com/document/d/1QlnqteubAzC6lSr5Pdm62D459U0kODPD/edit?usp=sharing&ouid=102363935403938524336&rtpof=true&sd=true' target='_blank'>Privacy Policy</a></span></Text>
          </VStack>
                </Box>
            </HStack>
        </Box>                
        </Flex>
        </Box>
        </>
    );
};

export default Login;