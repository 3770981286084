import React, {useState, useEffect} from 'react'
import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, Box, Flex, HStack, Text, Button } from "@chakra-ui/react"
import { simpleFetch } from '../../utils/fetchUtils'
import LogoutButton from '../auth/LogoutButton'
import { Link } from 'react-router-dom'

const ProfileDrawer = ({isOpen, onClose, loggedUser}) => {

  const [plan, setPlan] = useState('')
  const [endPeriod, setEndPeriod] = useState(null)
  const [manageSubscriptionNavigate, setManageSubscriptionNavigate] = useState(process.env.REACT_APP_BASE_URL + '/checkout')

  useEffect(() => {
    if (loggedUser !== null) {
    getPlanExpDate(loggedUser?.email).then((data) => {
      if(data) {
        setPlan(data.plan)
        setEndPeriod(data.expiring_date)
        if(data.plan !== null) {
          setManageSubscriptionNavigate(process.env.REACT_APP_STRIPE_PORTAL)
        }
      }
    })
  }
  }, [loggedUser])

  async function getPlanExpDate(user_id) {
    const fetch_url = process.env.REACT_APP_GET_PLAN_DETAILS_ROUTE || 'http://localhost:8080/stripe/get_plan_details'
    const res = await simpleFetch(fetch_url, 'POST', {user_id: user_id})
    return res
  }

  return (
        <>  
          <Drawer onClose={onClose} isOpen={isOpen} size="lg">
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerBody>
              <Box borderWidth={"1px"} p ="4" borderRadius={"16"} boxShadow={"md"} marginTop = "8">
                <Text fontSize = "xl" fontWeight={"semibold"} color = "gray.700">Account Information</Text>
                  <Flex marginTop = "4" width={"100%"} direction={"row"}>
                  <Box flex = {1}>
                    <HStack><Text color = "gray.700" fontWeight={"semibold"}>Email: </Text>
                  <Text color = "gray.700">{loggedUser?.email}</Text></HStack>
                  </Box>
                  <Button size = "sm" colorScheme='red' variant={"outline"} ><LogoutButton/></Button>
                  </Flex>
              </Box>
              <Box borderWidth={"1px"} p ="4" borderRadius={"16"} boxShadow={"md"} marginTop = "4">
                  <Text fontSize = "xl" fontWeight={"semibold"} color = "gray.700">Subscription Information</Text>
                  
                  <Flex marginTop = "4" width={"100%"} direction={"row"}>
                    <Box flex = {1}>
                    <Text color = "gray.700" fontWeight={"semibold"}>Current Plan: <span style={{fontWeight: "normal"}}>{plan}</span></Text>
                    </Box>
                   { <a
                        href={manageSubscriptionNavigate}
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                      <Button size = "sm" colorScheme='green' variant={"outline"}>Manage Subscription</Button>
                    </a> }
                  </Flex>
                  <Text color = "gray.700" fontWeight={"semibold"}>Subscription Period Ends: <span style={{fontWeight: "normal"}}>{endPeriod}</span></Text>
                </Box>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      )
}

export default ProfileDrawer