import { SimpleGrid, Box, Flex, Stack, VStack, Input, IconButton, Spinner, Fade, useDisclosure } from '@chakra-ui/react'
import { IoSend } from "react-icons/io5";
import { BsFillMicFill, BsFillPauseFill, BsFillRecordCircleFill } from "react-icons/bs"
import React, {useState, useEffect} from 'react'
import VideoPlayer from '../components/VideoPlayer'
import ChatBubble from '../components/ChatBubble';
import { useParams } from 'react-router-dom';
import AWS from 'aws-sdk';
import AudioPlayer from '../components/AudioPlayer';
import { useWhisper } from '@chengsokdara/use-whisper'
import { v4 as uuidv4 } from 'uuid';

const Test = () => {

  const { model_id } = useParams();

  const get_url_from_s3 = (s3Key, s3Bucket) => {
    console.log(s3Key)
    const params = {
      Bucket: s3Bucket,
      Key: s3Key,
      Expires: 60 * 5
    };

    return s3.getSignedUrl('getObject', params)    
  } 

  const [idleVideoUrl, setIdleVideoUrl] = useState( "../idle_sample.mp4");
  const [videoUrl, setVideoUrl] = useState("");
  const [audioUrl, setAudioUrl] = useState("");
  const [input, setInput] = useState("");
  const [chatLog, setChatLog] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [muted, setMuted] = useState(true);
  const sesion_id = uuidv4()

  //Speech to text
    //text to speech
    const {
        recording,
        speaking,
        transcribing,
        transcript,
        pauseRecording,
        startRecording,
        stopRecording,
      } = useWhisper({
        apiKey: process.env.REACT_APP_OPENAI_KEY,
        streaming: true,
        timeSlice: 1_000, 
        whisperConfig: {
          language: 'en',
        }, 
      });

  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_S3_KEY, // Replace with your access key ID
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY // Replace with your secret access key
  });


  useEffect(() => {
    setInput(transcript.text);
  }, [transcript]);


  //Handle Chat Message inpu
  async function handleSubmit(e) {
    e.preventDefault();

    if (loadingMessage) {
      return false;
    }

    const inputMessage = `${input}`;
    if (inputMessage === "") {
      alert("Cannot Submit Empty Message");
      return false;
    }

    var messageLog = [];
    for (let chat of chatLog) {
      var label = "";
      if (chat.sender === "Bot") {
        label = 'bot'
      } else {
        label = 'human'
      }

      messageLog.push("\n" + label + ": " + chat.message);
    }

    setLoadingMessage(true);
    let chatLogNew = [...chatLog, { message: inputMessage, sender: "User" }];
    setChatLog(chatLogNew);
    setInput("");

    console.log(JSON.stringify({model_id: model_id, user_id:'hyperreal', message: inputMessage, conv_history:messageLog}))
    // '/mitras/chat' - localhost
    // process.env.REACT_APP_CHAT_WITH_MODEl_ROUTE -  deployment
    const chat_route = process.env.REACT_APP_CHAT_WITH_MODEl_ROUTE || '/mitras/chat'
    var res;
    try {
      res = await fetch(chat_route, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer "+ process.env.REACT_APP_JWT_TOKEN,
        },
        body: JSON.stringify({model_id: model_id, user_id:'hyperreal', message: inputMessage, conv_history:messageLog, session_id:sesion_id}),
      });
    } catch (error) {
      setLoadingMessage(false);
      //catch timeout
      if (error.name === "AbortError") {
        alert("Timeout: Request took longer than 30 seconds to respond");
        return false;
      }
    }

    //catch any 500 error before trying to access its JSON response
    if (res.status === 500) {
      setLoadingMessage(false);
      alert("Server Unavailable: Please try again later");
      return false;
    }

    const data = await res.json();
    console.log(data)

    if (res.status != 200) {
      setLoadingMessage(false);
      alert(data.detail);
      return false;
    }

    //construct context for the message
    let context = data.metadata && data.metadata.file_name ? `${data.metadata.file_name}` : '';
    if (data.metadata && data.metadata.page_label) {
      context += ` - ${data.metadata.page_label}`;
    }

    let chatLogNew2 = [
      ...chatLogNew,
      {
        message: `${data.message}`,
        sender: "Bot",
        context: context,
      },
    ];
    setChatLog(chatLogNew2);

    //set video file here or audio url if no video url
    if (data.metadata.video_url) {
      const s3url = data.metadata.media_key
        ? get_url_from_s3(`${data.metadata.media_key}.mp4`, 'convo-ai')
        : data.metadata.video_url;
    
      setVideoUrl(s3url);
      setAudioUrl('');
    } else if (data.metadata.audio_url) {

      var audioFileExtension = 'wav'
      if(data.metadata.audio_url.includes('.mp3')){
        audioFileExtension = '.mp3'
      }

      const s3url = data.metadata.media_key
        ? get_url_from_s3(`${data.metadata.media_key+audioFileExtension}`, 'convo-ai') 
        : data.metadata.audio_url;
    
      setVideoUrl(idleVideoUrl);
      setAudioUrl(s3url);
    } else {
      setVideoUrl(idleVideoUrl);
      setAudioUrl(''); 
    }
    console.log(data)
    setLoadingMessage(false);
    return true;
  }

  function handleClearChat() {
    setChatLog([]);
  }

  //scroll all the way down
  function scrollDown() {
    const element2 = document.getElementById("chat-window-scroll");
    element2.scrollTop = 99999999999;
  }


  const handleAudioEnd = () => {
    console.log("video end triggered");
    setAudioUrl("")
  }

  const handleVideoEnd = () => {
    console.log("video end triggered");
    setVideoUrl(idleVideoUrl)
  }

  const videoStyles = {
    position: "absolute", // set the position of th e VideoPlayer to absolute
    maxHeight: '100%',
}


//set idle vid here 

const getIdleVid = async() => {
  var res;
    try {
      res = await fetch(process.env.REACT_APP_GET_MODEL_IDLE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer "+ process.env.REACT_APP_JWT_TOKEN,
        },
        body: JSON.stringify({model_id: model_id, user_id:'hyperreal'}),
      });
    } catch (error) {
      //catch timeout
      if (error.name === "AbortError") {
        alert("Timeout: Request took longer than 30 seconds to respond");
        return false;
      }
    }

    if (res.status != 200) {
      return false;
    }


    const data = await res.json();
    console.log("IDLE VIDEO KEY")
    console.log(data.idle_video_key)


    const idle_video_url = await get_url_from_s3(data.idle_video_key, 'convo-ai')
    return idle_video_url
}

useEffect( () => {
getIdleVid().then((url) => {
  setIdleVideoUrl(url || "../idle_sample.mp4");
  console.log("SET IDLE VIDEO URL")
  console.log(url)
});}, [] ) 

useEffect(() => {
  scrollDown();
}, [chatLog]);

useEffect(() => {
  console.log("video logged");
  console.log(videoUrl);
  console.log(idleVideoUrl);
}, [videoUrl, idleVideoUrl]);

useEffect(() => {
  console.log('audio logged')
  console.log(audioUrl)
}, [audioUrl]);


//set videoUrl on idleVideoUrl Change
//useEffect(() => { setVideoUrl(idleVideoUrl) }, [idleVideoUrl])

  return (
    <Flex w="full" h="100vh" direction ={{base: "column", sm:"column", md:"row", lg:"row"}}>
        <Box bg = "gray.100" width={{base: "100%", sm:"100%", md: "40%", lg: "512px", xl: "512px"}} height={{base: "40%", sm:"40%", md:"100vh"}} display="flex" alignItems={"center"} justifyContent={"center"} overflowY="hidden" position='relative'>
        <video
        style={{ ...videoStyles, zIndex: '1' }}
        autoPlay
        playsInline
        key={idleVideoUrl}
        muted
        loop
        top={{base: 0, sm: 0, md: "25%", lg:"15%", xl: "15%"}} 
        left = {"0"}
      > <source src={idleVideoUrl} type="video/mp4" /> </video>
        <VideoPlayer style={{ ...videoStyles, zIndex: '2', opacity: 0, transition: 'opacity 1s'}} key={videoUrl} videoUrl={videoUrl} idleVideoUrl={idleVideoUrl} handleVideoEnd={handleVideoEnd} top={{base: 0, sm: 0, md: "25%", lg:"15%", xl: "15%"}} left = {"0"}/>
          {audioUrl !== "" && <AudioPlayer url={audioUrl} handleAudioEnd={handleAudioEnd}/>}
        </Box>
            <Flex flex = {1} overflowY="scroll">
                    <Flex w="full" h="100%" flexDirection="column">
                        <Flex overflowY={"scroll"} flex={1} flexDirection="column" id="chat-window-scroll">
                            <Box paddingX = "4">
                            {chatLog.map((message, index) => (
                            <ChatBubble key={index} message={message} />
                            ))}
                            </Box>
                        </Flex>
                        <Flex
                        pl={3}
                        pr={2}
                        py={1}
                        borderTopColor="gray.200"
                        borderTopWidth="1px"
                        borderLeft="1px"
                        borderLeftColor="gray.200"
      >             
                        <Input
                          variant="unstyled"
                          placeholder="Type your message"
                          size={{base: "md", md: "sm"}}
                          id="message-input"
                          value={input}
                          onChange={(e) => setInput(e.target.value)}
                          onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            handleSubmit(e);
                          }
                          }}
                          />
                            {recording ? 
                              (
                                <IconButton
                                  colorScheme="red"
                                  variant="ghost"
                                  icon={<BsFillRecordCircleFill/>}
                                  onClick={() => {
                                  stopRecording();
                                }}
                                />
                                ) : (
                                  <>
                                  <IconButton
                                    colorScheme="purple"
                                    variant="ghost"
                                    icon={<BsFillMicFill/>}
                                    onClick={startRecording}
                                  />
                                  {loadingMessage ? (
                                    <IconButton
                                      colorScheme="purple"
                                      variant="ghost"
                                      icon={<Spinner />}
                                    />
                                    ) : (
                                    <IconButton
                                      colorScheme="purple"
                                      aria-label="Send message"
                                      variant="ghost"
                                      onClick={handleSubmit}
                                      icon={<IoSend />}
                                    />
                                  )}
                                </>
                              ) 
                            }
                    </Flex>
                </Flex>
        </Flex>
    </Flex>
  )
}

export default Test