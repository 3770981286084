import {
  FormControl,
  FormLabel,
  Heading,
  Input
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";

const GeneratorSettings = () => {
  const dispatch = useDispatch();
  const generator_provider = useSelector((state) => state.generator_provider);
  const generator_temp = useSelector((state) => state.generator_temperature);
  const generator_maxtoken = useSelector((state) => state.generator_maxtoken);

  //Had to put dispatch call inline with html for Sliders as the id reading did not work on them
  const handleSettingsInput = (e) => {
      dispatch({
        type: "setModelId",
        store: e.target.value,
      });
    }

  return (
    <FormControl p="6">
      <Heading as="h4" size="sm" marginBottom="2">
        Model Settings
      </Heading>
      <FormControl isRequired>
        <FormLabel fontSize="14px">Model ID</FormLabel>
        <Input
          placeholder="Input Model"
          size="sm"
          borderRadius="10"
          marginBottom="1"
          id="generator-provider"
          onChange={handleSettingsInput}
        >
        </Input>
      </FormControl>
    </FormControl>
  );
};

export default GeneratorSettings;
