import { SimpleGrid, Box, Flex, HStack, Image, VStack, Text, Input, IconButton, Spinner, Button, background } from '@chakra-ui/react'
import { IoSend } from "react-icons/io5";
import { BsFillMicFill, BsFillRecordCircleFill } from "react-icons/bs"
import React, {useState, useEffect, useRef} from 'react'
import VideoPlayer from '../components/VideoPlayer'
import ChatBubble from '../components/ChatBubble';
import { useParams } from 'react-router-dom';
import AWS from 'aws-sdk';
import AudioPlayer from '../components/AudioPlayer';
import { useWhisper } from '@chengsokdara/use-whisper'
import { v4 as uuidv4 } from 'uuid';
import { useDisclosure } from "@chakra-ui/react"
import ProfileDrawer from '../components/accounts/ProfileDrawer';
import LoginButton from '../components/auth/LoginButton';
import LogoutButton from '../components/auth/LogoutButton';
import { auth } from "../components/auth/firebase";
import { simpleFetch } from '../utils/fetchUtils';
import { set } from 'react-ga';
import PrettyAlert from '../components/alerts/PrettyGenericAlert';
import { Link } from 'react-router-dom';
import ReactSiriwave from 'react-siriwave';
import Waveform from '../components/Waveform';
import CustomImageComponent from '../components/CustomImageComponent';
import AudioStream from '../components/ElevenLabsStream';
import axios from "axios";

const Demo = () => {
  const [idleVideoUrl, setIdleVideoUrl] = useState( "../idle_sample.mp4");
  const [videoUrl, setVideoUrl] = useState("");
  const [audioUrl, setAudioUrl] = useState("");
  const [input, setInput] = useState("");
  const [chatLog, setChatLog] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [muted, setMuted] = useState(true);
  const { model_id } = useParams();
  const chat_model_id = model_id || 'Gb3JSAp18_hybrid_tts';
  const [sessionId, setSessionId] = useState('');
  const profilepDisclosure = useDisclosure();
  const subscriptionAlertDisclosure = useDisclosure();

  const whiteList = ['Erika@gitomer.com',
    'Stacey@gitomer.com',
    'Jeffrey@gitomer.com',
    'Jennifer@gitomer.com',
    'Lisa@gitomer.com',
    'Eric@gitomer.com',
    'shihanhaley@eliteforcemartialarts.com',
    'jay@thejayskinner.com',
    'rgreaves@gmail.com',
    'kenartis@yahoo.com',
    'ideaguy@gmail.com',
    'bradleycraigbeck@gmail.com',
    'dublander@hotmail.com',
    'wyattwoodsmall@gmail.com',
    'lsalz@salesarchitects.com',
    'monte@montewyatt.com',
    'wrestlebrunson@gmail.com',
    'bradsugars@aol.com',
    'james@actioncoach.co.uk',
    'ariel@augment.school',
    'roy@augment.school',
    'Marc@robinsongrowth.com',
    'therealgabbygitomer@gmail.com',
    'Admin@mitras.ai',
    'ralph.boral@hyperreal.io',
    'remington@hyperreal.io',
    'ash@enhance.online',
    'brian@mitras.ai',
    'aansh@mitras.ai',
  ]


  const imageMapping = { 'Little Red Book.pdf':{'imageSource':'../lbr.jpg', 'onClick':'', 'name':'Little Red Book'}, 
                         'The New Sales Bible FINAL.pdf':{'imageSource':'../salesbible.webp', 'onClick':'', 'name':'The Sales Bible'}, 
                         '22 Days':{'imageSource':'../21Day.png', 'onClick':'', 'name':'21.5 Days Sales Challenge'},
                        }
  const defaultImage = 'https://drive.google.com/uc?export=view&id=1ib2BvTuWHWb-ILFNoWsaQfeBjTAd01OU'
  const [imageSource , setImageSource] = useState(defaultImage)
  const [onClickImageSource, setOnClickImageSource] = useState('')
  const [pageNumber, setPageNumber] = useState('-');
  const [currentBook, setCurrentBook] = useState('-');
  const [audioStreamFlag, setAudioStreamFlag] = useState(false);
  const [currentMessage, setCurrentMessage] = useState('');
  const [renderStreaming, setRenderStreaming] = useState(false);

  //set to last whole conversation
  useEffect(() => {
    const newSessionId = uuidv4();
    setSessionId(newSessionId);
  }, []);


  //text to speech variables
  const {
    recording,
    speaking,
    transcribing,
    transcript,
    pauseRecording,
    startRecording,
    stopRecording,
  } = useWhisper({
    apiKey: process.env.REACT_APP_OPENAI_KEY,
    streaming: true,
    timeSlice: 1_000, 
    whisperConfig: {
      language: 'en',
    }, 
  });

  useEffect(() => {
    setInput(transcript.text);
  }, [transcript]);


  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_S3_KEY, // Replace with your access key ID
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY // Replace with your secret access key
  });

  const get_url_from_s3 = (s3Key, s3Bucket) => {
    const params = {
      Bucket: s3Bucket,
      Key: s3Key,
      Expires: 60 * 5
    };

    return s3.getSignedUrl('getObject', params)    
  } 


  //Handle Chat Message inpu
  async function handleSubmit(e) {
    e.preventDefault();
    //set stream to default in case handleEnd wasnt reached in the message before
    setAudioStreamFlag(false);
    setCurrentMessage('');
    setRenderStreaming(false);

    if (loadingMessage) {
      return false;
    }

    const inputMessage = `${input}`;
    if (inputMessage === "") {
      alert("Cannot Submit Empty Message");
      return false;
    }

    var messageLog = [];
    for (let chat of chatLog) {
      var label = "";
      if (chat.sender === "Bot") {
        label = 'bot'
      } else {
        label = 'human'
      }

      messageLog.push("\n" + label + ": " + chat.message);
    }

    setLoadingMessage(true);
    let chatLogNew = [...chatLog, { message: inputMessage, sender: "User" }];
    setChatLog(chatLogNew);
    setInput("");

    ///mitras/chat
    //process.env.REACT_APP_CHAT_WITH_MODEl_ROUTE
    var res;
    try {
      res = await fetch(process.env.REACT_APP_CHAT_WITH_MODEl_ROUTE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer "+ process.env.REACT_APP_JWT_TOKEN,
        },
        body: JSON.stringify({model_id: chat_model_id, user_id:'hyperreal', message: inputMessage, conv_history:messageLog, session_id:sessionId}),
      });
    } catch (error) {
      setLoadingMessage(false);
      //catch timeout
      if (error.name === "AbortError") {
        alert("Timeout: Request took longer than 30 seconds to respond");
        return false;
      }
    }

    //catch any 500 error before trying to access its JSON response
    if (res.status === 500) {
      setLoadingMessage(false);
      alert("Server Unavailable: Please try again later");
      return false;
    }

    const data = await res.json();

    if (res.status != 200) {
      setLoadingMessage(false);
      alert(data.detail);
      return false;
    }

    //construct context for the message
    let context = data.metadata && data.metadata.file_name ? `${data.metadata.file_name}` : '';
    if (data.metadata && data.metadata.page_label) {
      context += ` - ${data.metadata.page_label}`;
    }

    //set image source and onClick for Image View
    let newImage = data.metadata && data.metadata.file_name ? `${data.metadata.file_name}` : defaultImage;

    const chatLogLength = chatLog.length;
    //special case for 22 Day sales challenge
    if (newImage.startsWith('Day #')) {
      newImage = '22 Days'
    }
    if ((newImage in imageMapping) && (chatLogLength%10) === 0 && (chatLogLength > 0)) {
      setImageSource(imageMapping[newImage].imageSource)
      setOnClickImageSource(imageMapping[newImage].onClick)
      setCurrentBook(imageMapping[newImage].name)
    }
    else {
      setImageSource(defaultImage)
      setOnClickImageSource('')
      setCurrentBook('-')
    }
    //set page number
    let newPageNumber = data.metadata && data.metadata.page_label ? `${data.metadata.page_label}` : '-';
    setPageNumber(newPageNumber)

    let chatLogNew2 = [
      ...chatLogNew,
      {
        message: `${data.message}`,
        sender: "Bot",
        context: context,
      },
    ];
   
    setChatLog(chatLogNew2);

    //set video file here or audio url if no video url
    /*if (data.metadata.video_url) {
      const s3url = data.metadata.media_key
        ? get_url_from_s3(`${data.metadata.media_key}.mp4`, 'convo-ai')
        : data.metadata.video_url;
    
      setVideoUrl(s3url);
      setAudioUrl('');
    }*/ 
    //audio
    if (data.metadata.audio_url) {
      
      var audioFileExtension = 'wav'
      if(data.metadata.audio_url.includes('.mp3')){
        audioFileExtension = '.mp3'
      }

      const s3url = data.metadata.media_key
        ? get_url_from_s3(`${data.metadata.media_key+audioFileExtension}`, 'convo-ai') 
        : data.metadata.audio_url;
    
      setVideoUrl(idleVideoUrl);
      setAudioUrl(s3url);
      setLoadingMessage(false);
      setShowLastMessage(true);
    } 
    //video
    else if (data.metadata.video_url) {
      setVideoUrl(idleVideoUrl);
      setAudioUrl('');
    }
    //text only
    else {
      setCurrentMessage(data.message); 
      setShowLastMessage(false);
    }

    return true;
  }

  function handleClearChat() {
    setChatLog([]);
  }

  //scroll all the way down
  function scrollDown() {
    const element2 = document.getElementById("chat-window-scroll");
    element2.scrollTop = 99999999999;
  }

  const handleAudioEnd = () => {
    setAudioUrl("")
  }

  const handleVideoEnd = () => {
    setVideoUrl(idleVideoUrl)
  }

  const videoStyles = {
    position: "absolute", // set the position of th e VideoPlayer to absolute
    maxHeight: '100%',
}

//set idle vid here 
const getIdleVid = async() => {
  var res;
    try {
      res = await fetch(process.env.REACT_APP_GET_MODEL_IDLE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer "+ process.env.REACT_APP_JWT_TOKEN,
        },
        body: JSON.stringify({model_id: chat_model_id, user_id:'hyperreal'}),
      });
      if(!res.ok) {
        return false
      }
    }
      catch (error) {
        return false;
    }
    
    if (res.status != 200) {
      return false;
    }

    const data = await res.json();

    const idle_video_url = await get_url_from_s3(data.idle_video_key, 'convo-ai')
    return idle_video_url
}

useEffect( () => {
getIdleVid().then((url) => {
  setIdleVideoUrl(url || "../idle_sample.mp4");
});}, [] ) 

useEffect(() => {
  scrollDown();
}, [chatLog, loadingMessage]);

useEffect(() => {
  console.log("video logged");
}, [videoUrl, audioUrl]);

useEffect(() => {
  console.log('current message changed')
  console.log(currentMessage)
  if (currentMessage !== "" && !audioStreamFlag) {
      setAudioStreamFlag(true);
      setRenderStreaming(true);
      //startStreaming();
  }
}, [currentMessage])

//set videoUrl on idleVideoUrl Change
//useEffect(() => { setVideoUrl(idleVideoUrl) }, [idleVideoUrl])

const [loggedUser, setLoggedUser] = useState(null);

//Login + Logout listener
useEffect(() => {
  const unsubscribe = auth.onAuthStateChanged(async (newUser) => {
    setLoggedUser(newUser);
    if (newUser !== null) {
      await check_subscription(newUser)
    }
  });
  return unsubscribe;
}, []);

const check_subscription = async (newUser) => {
  const url = process.env.REACT_APP_GET_PLAN_DETAILS_ROUTE || "http://localhost:8000/mitras/get_plan_details";
    const res = await simpleFetch(url, 'POST', {user_id: newUser?.email})
    //check plan details
    if(res) {
      const currentDate = new Date();
      const expireDate = new Date(res.expiring_date + 'Z');
      if (whiteList.some(email => email.toLowerCase() === newUser?.email?.toLowerCase()) || expireDate > currentDate)
      {
        //plan valid
      }
      else {
        //If plan not valid
         subscriptionAlertDisclosure.onOpen()
      }
    }
    else {
      //If plan not valid
       subscriptionAlertDisclosure.onOpen()
    }
  }

  const handleSubscriptionPopupClose = () => {
    window.location.href = '/checkout'
    subscriptionAlertDisclosure.onClose()
  }

  function handleElevenLabsStreamEnd() {

    console.log('END ELERVEN LABS STREAM TRIGGERED')
    setAudioStreamFlag(false);
    setCurrentMessage('');
    setRenderStreaming(false);
  }

  function setLoadingOnAudioStreamStart(){
    setLoadingMessage(false);
    setShowLastMessage(true);
  }

  const [showLastMessage, setShowLastMessage] = useState(true);


  return (
    <Flex w="full" h="100vh" direction ={{base: "column", sm:"column", md:"row", lg:"row"}}>
          <PrettyAlert alertHeader={"Subscribe to Continue"} buttonMessage={"Start Subscription"} isOpen={subscriptionAlertDisclosure.isOpen} onClose={handleSubscriptionPopupClose}>
            <Text fontSize="lg" color="gray.600" textAlign={"center"}>Subscribe Now To Get <span style={{fontWeight: "bold", color: "#C41E3A"}}>More Answers</span> To Your <span style={{fontWeight: "bold", color: "#C41E3A"}}>Sales Questions</span></Text>
          </PrettyAlert>
        <Box 
        bgImage={"hyprreal_ai_bg.jpg"} 
        width={{base: "100%", sm:"100%", md: "60%", lg: "68%", xl: "68%"}} 
        height={{base: "40%", sm:"40%", md:"100vh"}} 
        display="flex" 
        alignItems={"center"} 
        justifyContent={"center"} 
        overflowY="hidden" 
        position='relative'
        bgPosition='center'
        bgRepeat='no-repeat'
        bgSize='cover'>
        { /*
        <video
        style={{ ...videoStyles, zIndex: '1' }}
        autoPlay
        playsInline
        key={idleVideoUrl}
        muted
        loop
        top={{base: 0, sm: 0, md: "25%", lg:"15%", xl: "15%"}} 
        left = {"0"}
      > <source src={idleVideoUrl} type="video/mp4" /> </video> 
       */}
       <VStack w = "100%" h = "100vh">
       <CustomImageComponent defaultImage={defaultImage} imageSource={imageSource} currentBook={currentBook} pageNumber={pageNumber} loadingMessage={loadingMessage} onClickImageSource={onClickImageSource} audioUrl={audioUrl}/>
          <AudioStream 
              key={currentMessage} // add a unique key
              text={currentMessage} 
              apiKey={process.env.REACT_APP_ELEVENLABS_API_KEY} 
              voiceId={"apbCyHT6rbJ5BhXphGhG"} 
              onAudioEnd={handleElevenLabsStreamEnd}
              onAudioStart={setLoadingOnAudioStreamStart}
              streamingFlag={audioStreamFlag}
              setAudioStreamFlag={setAudioStreamFlag}
          /> 

       <HStack paddingBottom={"8"} paddingTop={"12"}><Text color = {"skyblue"} fontSize={"16px"} fontWeight={"semibold"} textAlign={"center"}>Powered by</Text><Image src = "https://drive.google.com/uc?export=view&id=1fo3hFWnWxNQSJHNQ7KkaQW-KHODOVh1r" h={"18px"}/></HStack>
       </VStack>
      {/*<VideoPlayer style={{ ...videoStyles, zIndex: '2', opacity: 0, transition: 'opacity 1s'}} key={videoUrl} videoUrl={videoUrl} idleVideoUrl={idleVideoUrl} handleVideoEnd={handleVideoEnd} top={{base: 0, sm: 0, md: "25%", lg:"15%", xl: "15%"}} left = {"0"}/>
      */}
          {audioUrl !== "" && <AudioPlayer url={audioUrl} handleAudioEnd={handleAudioEnd}/>}
        </Box>
        <ProfileDrawer isOpen={profilepDisclosure.isOpen} onClose={profilepDisclosure.onClose} loggedUser={loggedUser} />
            <Flex flex = {1} overflowY="scroll" bg = "black">

                    <Flex w="full" h="100%" flexDirection="column">
                    <Box minW={"100%"} py = "3" px="4">
        <Flex direction={"row"}>
          <VStack w ="100%">
          <Image src="../gitomer_login.jpeg" borderRadius='full'
  boxSize='120px' paddingBottom={"3px"}/>
          <Flex flex={1}>
            <HStack>
            {/*loggedUser ? (<LogoutButton/>) : (<LoginButton/>)*/}
            <a href='https://forms.gle/mTPqJxjFtaPWnyk89' target='_blank'>
            <Button colorScheme="blue" variant = {"solid"} size="xs"> Give Feedback</Button>
            </a>
            <Button colorScheme="blue" variant = {"solid"} size="xs" onClick={profilepDisclosure.onOpen}> My Account</Button>
            <Button colorScheme="blue" variant={"solid"} size="xs" onClick={handleClearChat}> Clear Chat</Button>
            </HStack>
          </Flex>
          </VStack>
        </Flex>
    </Box>
                        <Flex overflowY={"scroll"} flex={1} flexDirection="column" id="chat-window-scroll">
                            <Box paddingX = "4">
                            {chatLog.map((message, index) => (
                    // Display all messages if showLastMessage is true or if it's not the last message
                    (showLastMessage || index !== chatLog.length - 1) && <ChatBubble key={index} message={message} />
                ))}
                            </Box>
                        </Flex>
                        <Flex
                        pl={3}
                        pr={2}
                        py={1}
                        bg = "white"
                        borderRadius={"lg"}
                        >             
                        <Input
                          variant="unstyled"
                          placeholder="Type your message"
                          size={{base: "md", md: "sm"}}
                          id="message-input"
                          value={input}
                          onChange={(e) => setInput(e.target.value)}
                          bg={"white"}
                          onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            handleSubmit(e);
                          }
                          }}
                          />                
                          {recording ? 
                              (
                                <IconButton
                                  colorScheme="blue"
                                  variant="solid"
                                  icon={<BsFillRecordCircleFill/>}
                                  onClick={() => {
                                  stopRecording();
                                }}
                                />
                                ) : (
                                  <>
                                  <IconButton
                                    colorScheme="blue"
                                    variant="ghost"
                                    icon={<BsFillMicFill/>}
                                    onClick={() => {
                                      startRecording();
                                      setTimeout(stopRecording, 15000); // 15000 milliseconds is equal to 15 seconds
                                    }}
                                  />
                                  {loadingMessage ? (
                                    <IconButton
                                      colorScheme="blue"
                                      variant="solid"
                                      icon={<Spinner />}
                                    />
                                    ) : (
                                    <IconButton
                                      colorScheme="blue"
                                      aria-label="Send message"
                                      variant="ghost"
                                      onClick={handleSubmit}
                                      icon={<IoSend />}
                                    />
                                  )}
                                </>
                              ) 
                            }
                    </Flex>
                </Flex>
        </Flex>
    </Flex>
  )
}

export default Demo