import React, { useState, useEffect } from 'react';

const AudioPlayer = ({url, handleAudioEnd}) => {
  var mimeType = 'audio/wav';
  if(url.includes('.mp3')){
    mimeType = 'audio/mpeg'
  }

  return (
    <audio hidden autoPlay playsInline key={url} onEnded={handleAudioEnd}>
        <source src={url} type={mimeType} />
    </audio>
  )
}

export default AudioPlayer