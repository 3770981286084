import React from 'react'
import { useRef, useState, useEffect } from 'react';

const VideoPlayer = ({style, videoUrl, idleVideoUrl, handleVideoEnd}) => {
    
  const [url, setVideoUrl] = useState(videoUrl);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const shouldMuteByDefault = isMobile;
  const [muted, setMuted] = useState(shouldMuteByDefault);
  const videoRef = useRef(null);

  const handleToggleMute = () => {
    setMuted(!muted);
    if (videoRef.current && !muted) {
      videoRef.current.currentTime = 0;
    }
  };

  const handleVideoLoad = () => {
    videoRef.current.style.opacity = 1;
  };

  return (
    <>
      <video
        style={style}
        autoPlay
        playsInline
        key={url}
        muted={muted || url === idleVideoUrl}
        loop={url === idleVideoUrl}
        onLoadedData={handleVideoLoad}
        onEnded={() => {handleVideoEnd(); videoRef.current.style.opacity = 0;}}
        ref={videoRef}
      >
        <source src={url} type="video/mp4" />
      </video>
      {isMobile ? (
        <button onClick={handleToggleMute}>{muted ? "Unmute" : "Mute"}</button>
      ) : (<></>)}
    </>
  )
}

export default VideoPlayer