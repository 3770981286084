import { configureStore } from "@reduxjs/toolkit";
import { createStore } from "redux";
import { v4 as uuidv4 } from "uuid";

const settings = {
  //set these to the default value of settings
  model_id: "",

};

const reducerFn = (state = settings, action) => {
  //Settings Page
  if (action.type === "setModelId") {
    return { ...state, model_id: action.store };
  }

  return state;
};
const store = createStore(reducerFn);
export default store;
